import { Elm } from "./Main.elm";
import Document from "flexsearch/dist/module/document"
import dataUrl from "url:../static/radiopaedia-articles.json"

const app = Elm.Main.init({ node: document.getElementById("root") });

const filter = ["the", "of"];

const index = new Document({
    tokenize: "full",
    index: ["keywords"],
    resolution: 9,
    store: true,
    charset: "latin:extra",
    filter: filter,
});

indexData()

async function indexData() {
    console.log("fetching started...")
    const response = await fetch(dataUrl)
    const entries = await response.json()
    console.log("fetching completed")
    const dbSize = entries.length
    console.log("total entries", dbSize)
    await app.ports.gotDbSize.send(dbSize);
    for (let i = 0; i < dbSize; i++) {
        index.add(entries[i]);
    }
    console.log("indexing complete")
    await app.ports.gotDbIndexProgress.send(1.0)
}

app.ports.search.subscribe(query => {
    console.log(query)
    index.searchAsync(query, { limit: 200, enrich: true, pluck: "keywords" }).then(result => {
        console.log(result)
        result = result.map(entry => entry.doc)
        app.ports.gotResult.send(result);
    })
})

app.ports.newTab.subscribe(url => window.open(url, '_blank'));