port module Main exposing (..)

import Browser
import Browser.Dom
import Browser.Events exposing (onKeyUp)
import Browser.Navigation exposing (back, load)
import Components exposing (draculaColor, raisedKey)
import Element exposing (Element, alignRight, centerX, centerY, column, el, fill, fillPortion, height, inFront, maximum, minimum, mouseDown, none, padding, paddingEach, paddingXY, paragraph, px, rgb255, row, spacing, text, width)
import Element.Background as Background
import Element.Border as Border
import Element.Events exposing (onClick)
import Element.Font as Font
import Element.Input as Input exposing (Label, Placeholder)
import Html exposing (Html)
import Html.Attributes exposing (selected)
import Html.Events
import Json.Decode as Decode
import List.Extra exposing (findIndex)
import Loading
import Maybe exposing (Maybe)
import String exposing (fromFloat, fromInt)
import Task
import Time


main : Program () Model Msg
main =
    Browser.element
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        }


type alias Model =
    { query : String
    , dbSize : Maybe Int
    , loadingState : Loading.LoadingState
    , result : List Entry
    , currentTime : Time.Posix
    , selected : Maybe Entry
    }


type alias Entry =
    { id : Int
    , title : String
    , keywords : String
    , url : String
    }


init : () -> ( Model, Cmd Msg )
init _ =
    ( { query = ""
      , dbSize = Nothing
      , result = []
      , loadingState = Loading.On
      , currentTime = Time.millisToPosix 0
      , selected = Nothing
      }
    , Cmd.batch [ Task.perform Tick Time.now ]
    )


subscriptions : Model -> Sub Msg
subscriptions model =
    Sub.batch
        [ gotResult GotResult
        , gotDbSize GotDbSize
        , gotDbIndexProgress GotDbIndexProgress
        , Time.every 1000 Tick
        , Browser.Events.onKeyDown keyDownDecoder
        ]


focusOn : String -> Cmd Msg
focusOn id =
    Task.attempt FocusResult (Browser.Dom.focus id)


focusOnInput : Cmd Msg
focusOnInput =
    focusOn "search-input"


focusOnResult : Cmd Msg
focusOnResult =
    focusOn "search-result"


type Msg
    = UpdateQuery String
    | GotDbSize Int
    | GotDbIndexProgress Float
    | GotResult (List Entry)
    | Tick Time.Posix
    | KeyPress String
    | FocusResult (Result Browser.Dom.Error ())
    | NoOp


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        UpdateQuery query ->
            ( { model | query = query, selected = Nothing }
            , search query
            )

        GotDbSize n ->
            ( { model | dbSize = Just n }, Cmd.none )

        GotDbIndexProgress pct ->
            let
                newLoadingState =
                    if pct == 1.0 then
                        Loading.Off

                    else
                        Loading.On
            in
            ( { model | loadingState = newLoadingState }, Cmd.none )

        GotResult result ->
            ( { model | result = result }, Cmd.none )

        Tick newTime ->
            ( { model | currentTime = newTime }, Cmd.none )

        KeyPress keypress ->
            case model.result of
                [] ->
                    ( model, Cmd.none )

                [ onlyOne ] ->
                    if keypress == "Enter" then
                        ( model, newTab onlyOne.url )

                    else
                        ( model, Cmd.none )

                first :: rest ->
                    {- case model.selected of
                       Nothing ->
                           case keypress of
                               "ArrowDown" ->
                                   ( { model | selected = Just first }, Cmd.none )

                               "ArrowUp" ->
                                   -- Focus to search bar
                                   ( model, focusOnInput )

                               _ ->
                                   ( model, Cmd.none )

                       Just selected ->
                           case keypress of
                               "ArrowUp" ->
                                   ( { model | selected = findPrevious selected model.result }, focusOnResult )

                               "ArrowDown" ->
                                   ( { model | selected = findNext selected model.result }, focusOnResult )

                               "Enter" ->
                                   ( model, newTab selected.url )

                               _ ->
                                   ( model, Cmd.none )
                    -}
                    ( model, Cmd.none )

        FocusResult result ->
            -- handle success or failure here
            case result of
                Err (Browser.Dom.NotFound id) ->
                    -- unable to find dom 'id'
                    ( model, Cmd.none )

                Ok () ->
                    -- successfully focus the dom
                    ( model, Cmd.none )

        NoOp ->
            ( model, Cmd.none )


findPrevious : Entry -> List Entry -> Maybe Entry
findPrevious target alist =
    findIndex (\x -> x.id == target.id) alist
        |> Maybe.andThen (\index -> List.Extra.getAt (index - 1) alist)


findNext : Entry -> List Entry -> Maybe Entry
findNext target alist =
    findIndex (\x -> x.id == target.id) alist
        |> Maybe.andThen (\index -> List.Extra.getAt (index + 1) alist)


port search : String -> Cmd msg


port gotDbSize : (Int -> msg) -> Sub msg


port gotDbIndexProgress : (Float -> msg) -> Sub msg


port gotResult : (List Entry -> msg) -> Sub msg


port newTab : String -> Cmd msg


view : Model -> Html Msg
view model =
    Element.layout
        [ Background.color draculaColor.bg
        , Element.clipX
        ]
        (body model)


body : Model -> Element Msg
body model =
    column
        [ Element.paddingEach
            { top = 50
            , right = 0
            , bottom = 25
            , left = 0
            }
        , width (fill |> maximum 800)
        , height fill
        , centerX
        , spacing 30
        ]
        [ title
        , searchBar model.query model.loadingState

        {- , if model.dbIndexProgress < 1.0 then
             progressBar model.dbIndexProgress model.dbSize

           else
             searchBar model.query
        -}
        , searchResult model.query model.result model.selected
        , footer
        ]


title : Element Msg
title =
    column [ centerX ]
        [ el
            [ Font.size 64
            , Font.family [ Font.typeface "Orbitron", Font.sansSerif ]
            , Font.color draculaColor.yellow
            , centerX
            ]
            (text "Radirect!")
        , paragraph
            [ Font.family [ Font.sansSerif ]
            , Font.size 16
            , Font.color draculaColor.purple
            , paddingEach
                { top = 8
                , right = 0
                , bottom = 0
                , left = 0
                }
            , centerX
            ]
            [ text "A "
            , el [ Font.bold ] (text "much better ")
            , text "search engine for Radiopaedia.org"
            ]
        ]


progressBar : Float -> Maybe Int -> Element Msg
progressBar progress maybeTotal =
    let
        percentage =
            progress * 100 |> round

        total =
            case maybeTotal of
                Just n ->
                    n

                Nothing ->
                    0

        loaded =
            round (progress * toFloat total)
    in
    row
        [ width fill
        , Background.color draculaColor.current
        , Font.color draculaColor.fg
        , Font.size 32
        , height (px 52)
        , Border.color draculaColor.pink
        , Border.width 4
        , inFront <|
            el [ Font.color draculaColor.comment, Font.size 12, centerX, centerY ] (text <| fromInt loaded ++ " out of " ++ fromInt total ++ " articles loaded")
        ]
        [ el [ Background.color draculaColor.yellow, height fill, width (fillPortion percentage) ] none
        , el [ height fill, width (fillPortion (100 - percentage)) ] none
        ]


searchBar : String -> Loading.LoadingState -> Element Msg
searchBar input loadingState =
    let
        loader =
            Element.inFront <|
                el
                    [ Element.centerX
                    , Element.centerY
                    ]
                    (searchbarLoader loadingState)
    in
    column
        [ width fill
        ]
        [ Input.text
            [ Background.color draculaColor.current
            , Font.color draculaColor.fg
            , Font.size 32
            , Border.color draculaColor.pink
            , Border.width 4
            , Input.focusedOnLoad
            , Element.htmlAttribute (Html.Attributes.id "search-input")
            , loader
            ]
            { onChange = UpdateQuery
            , text = input
            , placeholder = Nothing
            , label = Input.labelHidden "Search term"
            }
        , paragraph
            [ padding 10
            , centerX
            , Font.color draculaColor.comment
            , Font.size 16
            , Font.light
            , Font.center
            ]
            [ text "Type "
            , el [ Font.color draculaColor.pink ] (text "something ")
            , text "to search..."

            {- , row
                   [ spacing 5
                   , paddingXY 5 0
                   ]
                   [ text ", use"
                   , raisedKey "🢁"
                   , raisedKey "🢃"
                   , text "and"
                   , raisedKey "Enter"
                   ]
               , text "to navigate."
            -}
            ]
        ]


searchbarLoader : Loading.LoadingState -> Element Msg
searchbarLoader loadingState =
    let
        config =
            { size = 30
            , color = "#74b4c9"
            , className = ""
            , speed = 2
            }
    in
    Element.html <| Loading.render Loading.Spinner config loadingState


searchResult : String -> List Entry -> Maybe Entry -> Element Msg
searchResult lastQuery entries maybeSelected =
    let
        viewKeywords e =
            let
                queryWords =
                    String.split " " lastQuery

                testQueryWord kw qw =
                    String.contains (String.toLower qw) (String.toLower kw)

                matchedKeywords =
                    e.keywords
                        |> String.split ","
                        |> List.map String.trim
                        |> List.filter (\kw -> String.toLower kw /= String.toLower e.title)
                        |> List.filter (\kw -> List.all (testQueryWord kw) queryWords)
                        |> List.take 3
            in
            paragraph
                [ Font.size 12
                , Font.color draculaColor.comment
                ]
                [ text <| String.join ", " matchedKeywords ]

        viewEntry e =
            let
                isSelected =
                    case maybeSelected of
                        Just selected ->
                            if selected.id == e.id then
                                True

                            else
                                False

                        Nothing ->
                            False

                selectedAttributes =
                    if isSelected then
                        [ Border.width 2
                        ]

                    else
                        [ Border.width 0 ]
            in
            column
                ([ Font.size 22
                 , Background.color draculaColor.bg
                 , Font.color draculaColor.cyan
                 , padding 5
                 , height (px 68)
                 , width fill
                 , spacing 3
                 , Element.scrollbarX
                 , Element.htmlAttribute (Html.Attributes.id "search-result")
                 ]
                    ++ selectedAttributes
                )
                [ Element.newTabLink
                    [ Element.mouseOver [ Font.color draculaColor.pink ]
                    ]
                    { url = e.url, label = paragraph [] [ text e.title ] }
                , viewKeywords e
                ]
    in
    column
        [ spacing 3
        , width fill
        , Element.paddingXY 24 0
        ]
        (List.map viewEntry entries)


keyDownDecoder : Decode.Decoder Msg
keyDownDecoder =
    Decode.field "key" Decode.string
        |> Decode.map KeyPress


footer : Element Msg
footer =
    column
        [ Font.color draculaColor.comment
        , Font.size 16
        , centerX
        , Element.alignBottom
        , width fill
        , spacing 10
        ]
        [ paragraph [ centerX, Font.center ]
            [ text "All results from "
            , Element.newTabLink
                [ Font.underline ]
                { url = "https://www.radiopaedia.org/", label = el [] (text "Radiopaedia.org") }
            ]
        , paragraph [ centerX, Font.center ]
            [ Element.newTabLink
                []
                { url = "https://www.tubo.nz/", label = el [] (text "Tubo Shi") }
            , text ", 2023"
            ]
        ]
